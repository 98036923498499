<template>
  <div class="vap-page">
    <div class="vap-page-header">
      <h1>{{ documentTitle }}</h1>
      <div class="btns-container">
        <VasionButton
            id="save-attributes-button"
            :classProp="'primary'"
            :isFullWidth="false"
            @vasionButtonClicked="save()"
          >
            OK
          </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
      <div class="md-layout toolbar-div">
        <div class="control-box">
          <VasionButton
            id="btnFirstPage"
            class="action-link"
            name="btnFirstPage"
            title="First Page"
            :icon="'VasionFirstPageIcon'"
            :isDisabled="disablePreviousButtons"
            @vasionButtonClicked="goToFirstPage"
          />
          <VasionButton
            id="btnPreviousPage"
            class="action-link"
            name="btnPreviousPage"
            title="Previous Page"
            :icon="'VasionPreviousPageIcon'"
            :isDisabled="disablePreviousButtons"
            @vasionButtonClicked="goToPreviousPage"
          />
          <VasionInput
            v-model="pageDisplay"
            :readOnly="true"
            inputType="blank-white"
            width="94"
            class="action-input"
          />
          <VasionButton
            id="btnNextPage"
            class="action-link"
            name="btnNextPage"
            title="Next Page"
            :icon="'VasionNextPageIcon'"
            :isDisabled="disableNextButtons"
            @vasionButtonClicked="goToNextPage"
          />
          <VasionButton
            id="btnLastPage"
            class="action-link"
            name="btnLastPage"
            title="Last Page"
            :icon="'VasionLastPageIcon'"
            :isDisabled="disableNextButtons"
            @vasionButtonClicked="goToLastPage"
          />
          <div class="divider" />
          <VasionButton
            id="zoom-in"
            class="action-link"
            name="zoom-in"
            title="Zoom In"
            :icon="'VasionZoomInIcon'"
            @vasionButtonClicked="zoomIn"
          />
          <VasionButton
            id="zoom-out"
            class="action-link"
            name="zoom-out"
            title="Zoom Out"
            :icon="'VasionZoomOutIcon'"
            @vasionButtonClicked="zoomOut"
          />
          <div class="divider" />
          <VasionButton
            id="check-zones"
            class="action-link"
            name="check-zones"
            title="Add Check Zone"
            :icon="'VasionHistoryIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.checkZone)"
          />
          <VasionButton
            id="text-file"
            class="action-link"
            name="text-file"
            title="Add Text Zone"
            :icon="'VasionFileUpsideDownIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.textZone)"
          />
          <VasionButton
            id="barcode-icon"
            class="action-link colorBlack"
            name="barcode-icon"
            title="Add Barcode Zone"
            :icon="'VasionZoneBarcodeIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.barcodeZone)"
          />
          <VasionButton
            id="omr-icon"
            class="action-link"
            name="omr-icon"
            title="Add OMR Zone"
            :icon="'VasionOcrIcon'"
            @vasionButtonClicked="addNewAnnotation(annotationConfig.omrZone)"
          />
        </div>
      </div>
      <div class="md-layout main-content">
        <div class="md-layout-item middle-section">
          <div class="document-loaded">
            <DocumentImage
              v-if="uploadedfilePath && uploadedfilePath !== ''"
              ref="mainImage"
              :key="currentPageNumber"
              :documentID="0"
              :pageNumber="currentPageNumber"
              :width="1632"
              :height="2112"
              :largeDefaultLoadSize="true"
              :context="'AIPZones'"
              :imageEndpoint="'document/getFileImageURL'"
              :filePath="uploadedfilePath"
              @backgroundImageLoaded="setZoom"
              @fileImageLoaded="mainImageLoaded"
            />
          </div>
        </div>
        <div class="right-sidebar">
          <div class="right-sidebar-title">
            {{ zoneDetailsPanelType }} Details
          </div>
          <div v-if="zoneDetailsPanelType === annotationConfig.checkZone.subType">
            <VasionInput
              id="check-zone-name"
              v-model="currentCheckZoneData.checkName"
              class="panel-component-bottom-padding"
              title="Name"
              name="check-zone-name"
              inputType="top-white"
              :required="true"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionCheckbox
              id="check-zone-look-for-barcode"
              class="panel-component-bottom-padding"
              name="check-zone-look-for-barcode"
              :checked="currentCheckZoneData.isBarcode"
              @change="toggleCheckZoneLookForBarcode"
            >
              Look for Barcode
            </VasionCheckbox>

            <VasionDropList
              id="check-zone-compare-value"
              v-slot="slotItem"
              v-model="currentCheckZoneData.compareValueObject"
              class="panel-component-bottom-padding-small"
              name="check-zone-compare-value"
              :dataArray="checkZoneCompareValueOptions"
              :width="'287'"
              title="Read Value"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeCheckZoneCompareValue"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionInput
              id="check-zone-text-compare"
              v-model="currentCheckZoneData.textCompare"
              class="panel-component-bottom-padding"
              :isDisabled="currentCheckZoneData.compareValue === ''"
              title=""
              name="check-zone-text-compare"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionInput
              id="check-zone-other-pages"
              v-model="currentCheckZoneData.pageNumbersString"
              class="panel-component-bottom-padding"
              title="Other Pages to Read For Check Zones"
              name="check-zone-other-pages"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionCheckbox
              id="check-zone-move-page-check"
              name="check-zone-move-page-check"
              :checked="currentCheckZoneData.moveToPageChecked"
              @change="toggleCheckZoneMoveToPage"
            >
              Move the Found Value Page to Page #:
            </VasionCheckbox>

            <VasionInput
              id="check-zone-move-to-page"
              v-model="currentCheckZoneData.moveToPage"
              title=""
              name="check-zone-move-to-page"
              inputType="top-white"
              specialType="number"
              :min="1"
              :isDisabled="!currentCheckZoneData.moveToPageChecked"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />
          </div>
          <div v-else-if="zoneDetailsPanelType === annotationConfig.omrZone.subType">
            <VasionInput
              id="omr-zone-name"
              v-model="currentOmrZoneData.name"
              class="panel-component-bottom-padding"
              title="Name"
              name="omr-zone-name"
              inputType="top-white"
              :required="true"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              id="omr-zone-type-value"
              v-slot="slotItem"
              v-model="omrTypeObj"
              class="panel-component-bottom-padding-small"
              name="omr-zone-type-value"
              :dataArray="omrTypeList"
              :width="'287'"
              title="OMR TYPE"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeOmrZoneOmrType"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionDropList
              id="omr-zone-field-value"
              v-slot="slotItem"
              v-model="currentOmrZoneData.indexFieldObject"
              class="panel-component-bottom-padding-small"
              name="omr-zone-field-value"
              :dataArray="indexFieldsForForm"
              :width="'287'"
              title="FIELD"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeOmrZoneFieldValue"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionInput
              id="omr-zone-percent-full"
              v-model="currentOmrZoneData.percentFull"
              class="panel-component-bottom-padding"
              title="PERCENT FULL"
              name="omr-zone-percent-full"
              inputType="top-white"
              type="number"
              min="1"
              max="100"
              @input="checkPercentFull"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />
            <VasionInput
              id="omr-zone-full-value"
              v-model="currentOmrZoneData.fullText"
              class="panel-component-bottom-padding"
              title="FULL VALUE"
              name="omr-zone-full-value"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />
            <VasionInput
              id="omr-zone-EMPTY-value"
              v-model="currentOmrZoneData.emptyText"
              class="panel-component-bottom-padding"
              title="EMPTY VALUE"
              name="omr-zone-empty-value"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />
            <VasionCheckbox
              id="omr-zone-append-data"
              name="omr-zone-append-data"
              :checked="currentOmrZoneData.appendValue"
              @change="toggleOmrZoneAppendData"
            >
              Append Data
            </VasionCheckbox>
          </div>

          <div v-else-if="zoneDetailsPanelType === annotationConfig.textZone.subType">
            <VasionInput
              id="check-zone-name"
              v-model="currentTextOrBarcodeData.name"
              class="panel-component-bottom-padding"
              title="Name"
              name="check-zone-name"
              inputType="top-white"
              :required="true"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              v-if="indexFieldsForForm.length > 0"
              id="text-zone-field-value"
              v-slot="slotItem"
              v-model="currentTextOrBarcodeData.field"
              class="panel-component-bottom-padding-small"
              name="text-zone-field-value"
              :dataArray="indexFieldsForForm"
              title="Field"
              :width="'287'"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeTextZoneField"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionCheckbox
              id="text-zone-append-text"
              class="panel-component-bottom-padding"
              name="text-zone-append-text"
              :checked="currentTextOrBarcodeData.appendData"
              @change="toggleTextZoneAppendText"
            >
              Append Text to Existing Field Data
            </VasionCheckbox>

            <VasionCheckbox
              id="text-zone-remove-all-spaces"
              class="panel-component-bottom-padding"
              name="text-zone-remove-all-spaces"
              :checked="currentTextOrBarcodeData.removeSpaces"
              @change="toggleTextZoneRemoveSpaces"
            >
              Remove All Spaces
            </VasionCheckbox>

            <VasionInput
              id="text-zone-append-symbol"
              v-model="currentTextOrBarcodeData.concatSymbol"
              class="panel-component-bottom-padding"
              title="APPEND SYMBOL"
              name="text-zone-append-symbol"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              id="text-zone-character-filter"
              v-slot="slotItem"
              v-model="characterFilter"
              class="panel-component-bottom-padding-small"
              name="text-zone-character-filter"
              :dataArray="characterFilterList"
              title="CHARACTER FILTER"
              :width="'287'"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeTextZoneCharacterFilter"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionCheckbox
              id="text-zone-burst-text-found"
              class="panel-component-bottom-padding"
              name="text-zone-burst-text-found"
              :checked="currentTextOrBarcodeData.burstOnTextFound"
              @change="toggleTextZoneBurstOnTextFound"
            >
              Burst on Text Found
            </VasionCheckbox>

            <VasionInput
              id="text-zone-optional-burst-values"
              v-model="currentTextOrBarcodeData.burstTextValues"
              class="panel-component-bottom-padding"
              title="OPTIONAL BURST VALUES"
              name="text-zone-optional-burst-values"
              inputType="top-white"
              :isDisabled="!disableBurstValues"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionCheckbox
              id="text-zone-extract-text"
              class="panel-component-bottom-padding"
              name="text-zone-extract-text"
              :checked="currentTextOrBarcodeData.extractText"
              @change="toggleTextExtractText"
            >
              Extract Text
            </VasionCheckbox>

            <VasionInput
              id="text-zone-delimiter-symbol"
              v-model="currentTextOrBarcodeData.extractDelChar"
              class="panel-component-bottom-padding"
              title="DELIMITER SYMBOL"
              name="text-zone-delimiter-symbol"
              inputType="top-white"
              :isDisabled="!disableExtractValues"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              id="text-zone-extract-word-amount"
              v-slot="slotItem"
              v-model="currentTextOrBarcodeData.extractWordAmount"
              class="panel-component-bottom-padding-small"
              name="text-zone-extract-word-amount"
              :dataArray="wordAmountList"
              :isDisabled="!disableExtractValues"
              title="EXTRACT WORD AMOUNT"
              :width="'287'"
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="changeTextZoneExtractWordAmount"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionInput
              id="text-zone-extract-starting-position"
              v-model="currentTextOrBarcodeData.extractPosition"
              class="panel-component-bottom-padding"
              title="EXTRACT STARTING POSITION"
              name="text-zone-extract-starting-position"
              inputType="top-white"
              type="number"
              min="1"
              :isDisabled="!disableExtractValues"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              id="text-zone-extract-direction"
              v-slot="slotItem"
              v-model="extractDirection"
              class="panel-component-bottom-padding-small"
              name="text-zone-extract-direction"
              :dataArray="extractDirectionList"
              :isDisabled="!disableExtractValues"
              :width="'287'"
              title="EXTRACT DIRECTION"
              type="plain-list"
              displayName="name"
              valueName="value"
              @input="changeTextZoneExtractDirection"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-else-if="zoneDetailsPanelType === annotationConfig.barcodeZone.subType">
            <VasionInput
              id="check-zone-name"
              v-model="currentTextOrBarcodeData.name"
              class="panel-component-bottom-padding"
              title="Name"
              name="check-zone-name"
              inputType="top-white"
              :required="true"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              v-if="indexFieldsForForm.length > 0"
              id="text-zone-field-value"
              v-slot="slotItem"
              v-model="currentTextOrBarcodeData.field"
              class="panel-component-bottom-padding-small"
              name="text-zone-field-value"
              :dataArray="indexFieldsForForm"
              title="Field"
              :width="'287'"
              type="plain-list"
              :required="true"
              displayName="name"
              valueName="value"
              @input="changeTextZoneField"
            >
              {{ slotItem.item.name }}
            </VasionDropList>

            <VasionCheckbox
              id="text-zone-append-text"
              class="panel-component-bottom-padding"
              name="text-zone-append-text"
              :checked="currentTextOrBarcodeData.appendData"
              @change="toggleTextZoneAppendText"
            >
              Append Text to Existing Input Field
            </VasionCheckbox>

            <VasionCheckbox
              id="text-zone-remove-all-spaces"
              class="panel-component-bottom-padding"
              name="text-zone-remove-all-spaces"
              :checked="currentTextOrBarcodeData.removeSpaces"
              @change="toggleTextZoneRemoveSpaces"
            >
              Remove All Spaces
            </VasionCheckbox>

            <VasionInput
              id="text-zone-append-symbol"
              v-model="currentTextOrBarcodeData.concatSymbol"
              class="panel-component-bottom-padding"
              title="APPEND SYMBOL"
              name="text-zone-append-symbol"
              inputType="top-white"
              @focusin="setDeleteOnInput"
              @focusout="clearDeleteOnInput"
            />

            <VasionDropList
              id="text-zone-character-filter"
              v-slot="slotItem"
              v-model="characterFilter"
              class="panel-component-bottom-padding-small"
              name="text-zone-character-filter"
              :dataArray="characterFilterList"
              title="CHARACTER FILTER"
              :width="'287'"
              type="plain-list"
              displayName="name"
              valueName="value"
              :required="true"
              @input="changeTextZoneCharacterFilter"
            >
              {{ slotItem.item.name }}
            </VasionDropList>
          </div>
          <div v-else class="no-zone-selected">
            <VasionLargeOcrIcon class="no-zone-image" />
            <span class="no-zone-selected-title"> No Zone Selected </span>
            <span class="no-zone-selected-message"> Add a zone and select it to view and edit its <br> settings and details </span>
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="document-uploaded-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import DocumentImage from '@/components/document/DocumentImage.vue';

export default {
  name: 'AipZoneConfiguration',
  components: {
    DocumentImage,
  },
  data: function () {
    return {
      annotationConfig: {
        barcodeZone: { type: 'aipZone', color: '', subType: 'Barcode Zone' },
        checkZone: { type: 'aipZone', color: '', subType: 'Check Zone' },
        omrZone: { type: 'aipZone', color: '', subType: 'OMR Zone' },
        textZone: { type: 'aipZone', color: '', subType: 'Text Zone' },
      },
      characterFilter: {
          name: '',
          value: 0,
      },
      checkZoneCompareValueOptions: [
        {
          name: '',
          value: '',
        },
        {
          name: 'Equals',
          value: 'Equals',
        },
        {
          name: 'Contains',
          value: 'Contains',
        },
        {
          name: 'Starts With',
          value: 'Starts With',
        },
        {
          name: 'Ends With',
          value: 'Ends With',
        },
      ],
      checkZoneDataArray: [],
      currentCheckZoneData: {
        annGUID: '',
        checkID: 0,
        checkName: '',
        compareValue: '',
        compareValueObject: {
          name: '',
          value: '',
        },
        isBarcode: false,
        moveToPage: null,
        moveToPageChecked: false,
        originPageNumber: 1,
        pageNumbers: [],
        pageNumbersString: '',
        rotateZone: false,
        textCompare: '',
      },
      currentOmrZoneData: {
        aipId: 0,
        annGuid: '',
        appendValue: false,
        fullText: 'True',
        emptyText: 'False',
        indexFieldObject: { name: '', value: '' },
        indexFieldName: '',
        name: '',
        omrTypeId: 0,
        percentFull: 0,
        originPageNumber: this.currentPageNumber,
        originalImageHeightInches: 100.00,
        originalImageWidthInches: 100.00,
        rotateZone: false,
      },
      currentPageNumber: 1,
      currentTextOrBarcodeData: {
        annGuid: '',
        aipId: 0,
        charSet: 0,
        concatSymbol: '',
        concatData: false,
        appendData: false,
        burstOnTextFound: false,
        burstTextValues: '',
        burstOnBarcode: false,
        extractDelChar: ' ',
        extractFromLeft: false,
        extractPosition: 0,
        extractText: false,
        extractWordCount: 0,
        indexFieldName: '',
        isBarcode: false,
        meanConfidence: 0.00,
        originalImageHeightInches: 100.00,
        originalImageWidthInches: 100.00,
        originPageNumber: this.currentPageNumber,
        removeSpaces: false,
        zoneProcessingID: 0,
        field: {
          name: '',
          value: '',
        },
      },
      extractDirection: {
          name: '',
          value: '',
      },
      name: '',
      omrTypeObj: { name: '', value: 0 },
      omrZoneDataArray: [],
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      textAndBarcodeZoneDataArray: [],
      totalPages: 0,
      uploadedFileName: '',
      uploadedfilePath: '',
      zoneDetailsPanelType: '',
    }
  },
  computed: {
    activeAIPZone() { return this.$store.state.capture.selectedAIPZone },
    characterFilterList() {
      return [
        {
          name: 'Alpha',
          value: 1,
        },
        {
          name: 'Numeric',
          value: 2,
        },
        {
          name: 'Alphanumeric',
          value: 0,
        },
      ]
    },
    disableBurstValues() { return this.currentTextOrBarcodeData.burstOnTextFound },
    disableExtractValues() { return this.currentTextOrBarcodeData.extractText },
    disableNextButtons() { return this.currentPageNumber >= this.totalPages },
    disablePreviousButtons() { return this.currentPageNumber <= 1 },
    documentTitle() { return 'Zone Configuration' },
    extractDirectionList() {
      return [
        {
          name: 'Left',
          value: 1,
        },
        {
          name: 'Right',
          value: 2,
        },
      ]
    },
    filePondLabel() {
      return `<span class="filepond-drag">Drag TIFF or PDF File Here</span>
              <br /><br/>
              <span class="filepond-drag-or">or</span>
              <br /><br />
              <span class="filepond-label-action">Upload New Document</span>`
    },
    indexFieldsForForm() { return this.$store.state.common.indexFields ? this.$store.state.common.indexFields : [] },
    omrTypeList() {
      return [
        {
          name: 'Area is Filled',
          value: 1,
        },
        {
          name: 'Canadian Zip Code',
          value: 2,
        },
        {
          name: 'Canadian Zip Code 2',
          value: 3,
        },
      ]
    },
    pageDisplay: {
      get: function () {
          return this.totalPages <= 0 ? '' : `${this.currentPageNumber}/${this.totalPages}`
      },
      set: function () {
      },
    },
    selectedFormId() { return this.$store.state.capture.selectedFormId },
    tempUploadedFile() { return this.$store.state.capture.tempFile },
    uniqueFieldNames() { return this.$store.state.storage.uniqueFieldNames },
    wordAmountList() {
      return [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
        {
          name: 4,
        },
        {
          name: 5,
        },
      ]
    },
    zoomValue() { return this.$store.state.document.zoomValue },
  },
  watch: {
    activeAIPZone: function () {
      this.displayCurrentZonePanel()
      this.updateCurrentZoneData()
    },
    currentPageNumber: function () {
      // this essentially unselects the current annotation from a data perspective
      this.$store.dispatch('capture/setSelectedAIPZone', {
        uniqueId: '',
        subType: '',
      })
    },
  },
  async created() {
    this.$store.dispatch('storage/loadUniqueFieldNames')
    this.uploadedfilePath = this.tempUploadedFile
    const payload = {
      Value: this.uploadedfilePath,
    }
    await this.$store.dispatch('common/getIndexFieldsForForm', this.selectedFormId)
    this.$store.dispatch('document/resetZoomValue')
    this.totalPages = await this.$store.dispatch('document/getFilePageCount', payload)
    if (this.$store.state.capture.aipCheckZoneArray) {
      // make a copy of the store data
      this.checkZoneDataArray = JSON.parse(JSON.stringify(this.$store.state.capture.aipCheckZoneArray))

      this.checkZoneDataArray.map((element) => {
        if (element.compareValueObject === undefined) {
          element.compareValueObject = {
            name: element.compareValue,
            value: element.compareValue,
          }
        }

        if (element.pageNumbersString === undefined) {
          element.pageNumbersString = element.pageNumbers ? element.pageNumbers.toString() : ''
        }

        return true
      })
    }
    if (this.$store.state.capture.aipOmrZoneArray) {
      this.omrZoneDataArray = JSON.parse(JSON.stringify(this.$store.state.capture.aipOmrZoneArray))
    }
    if (this.$store.state.capture.aipTextZoneArray) {
      this.textAndBarcodeZoneDataArray = JSON.parse(JSON.stringify(this.$store.state.capture.aipTextZoneArray))
    }
  },
  methods: {
    mainImageLoaded(imageData) {
      if ((this.viewerHeight > this.viewerWidth && imageData.height < imageData.width)
        || (this.viewerHeight < this.viewerWidth && imageData.height > imageData.width)) {
          const temp = this.viewerHeight
          this.viewerHeight = this.viewerWidth
          this.viewerWidth = temp
        }

      if (this.viewerHeight > imageData.height || this.viewerWidth > imageData.width) {
        this.viewerHeight = imageData.height
        this.viewerWidth = imageData.width
      }
      this.setZoom()
    },
    async setZoom() {
      await this.$store.dispatch('document/resetZoomValue')
      if (window.innerWidth <= 416) {
        this.$store.dispatch('document/setZoomValue', .2)
      } else if (window.innerWidth <= 1430) {
        this.$store.dispatch('document/setZoomValue', .3)
      } else if (window.innerWidth <= 1630) {
        this.$store.dispatch('document/setZoomValue', .4)
      } else if (window.innerWidth <= 1870) {
        this.$store.dispatch('document/setZoomValue', .44)
      } else {
        this.$store.dispatch('document/setZoomValue', .54)
      }
    },
    addNewAnnotation(config) { this.$store.dispatch('document/setAnnotationConfig', config) },
    changeCheckZoneCompareValue(newVal) { this.currentCheckZoneData.compareValue = newVal ? newVal.value : '' },
    changeOmrZoneFieldValue(newVal) { this.currentOmrZoneData.indexFieldName = newVal ? newVal.value : '' },
    changeOmrZoneOmrType(newVal) { this.currentOmrZoneData.omrTypeId = newVal ? newVal.value : '' },
    changeTextZoneCharacterFilter(newVal) { this.currentTextOrBarcodeData.charSet = newVal ? newVal.value : '' },
    changeTextZoneExtractDirection(newVal) { this.currentTextOrBarcodeData.extractFromLeft = newVal.value === 1 },
    changeTextZoneExtractWordAmount(newVal) { this.currentTextOrBarcodeData.extractWordCount = newVal ? newVal.name : '' },
    changeTextZoneField(newVal) { this.currentTextOrBarcodeData.indexFieldName = newVal ? newVal.value : '' },
    checkPercentFull() {
      if (this.currentOmrZoneData.percentFull > 100 || this.currentOmrZoneData.percentFull <= 0 || !this.currentOmrZoneData.percentFull) {
        this.showSnackbarBool = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Please make sure Percent Full is a value between 1-100'
        this.currentOmrZoneData.percentFull = 0
      }
    },
    cleanAndValidateData() {
      this.updateCurrentZoneData()

      if (!this.checkZoneDataArray) {
        this.checkZoneDataArray = []
      }

      // remove any zones that do not have an annotation associated with it
      this.checkZoneDataArray.filter((element) => {
        return element.annGUID !== ''
      })

      if (!this.checkZoneDataArray || this.checkZoneDataArray.length <= 0) {
        return true
      }

      let isValid = true
      this.checkZoneDataArray.map((checkZone) => {
        if (!checkZone.checkName || checkZone.checkName.trim() === '') {
          isValid = false
        }

        checkZone.compareValue = checkZone.compareValueObject?.value ? checkZone.compareValueObject.value : ''
        if (!checkZone.compareValue) {
          isValid = false
        }

        if (!checkZone.textCompare) {
          isValid = false
        }

        if (!checkZone.moveToPageChecked) {
          checkZone.moveToPage = null
        }

        let stringArray = checkZone.pageNumbersString ? checkZone.pageNumbersString.split(',') : []
        stringArray = stringArray.filter((element) => {
          // eslint-disable-next-line no-restricted-globals
          return isNaN(parseInt(element, 10)) === false
        })

        checkZone.pageNumbers = stringArray.map((element) => {
          return parseInt(element.trim(), 10)
        })

        return true
      })
      return isValid
    },
    cleanAndValidateOmrData() {
      this.updateCurrentZoneData()

      if (!this.omrZoneDataArray) {
        this.omrZoneDataArray = []
      }

      this.omrZoneDataArray.filter(e => {
        return e.annGuid !== ''
      })

      if (!this.omrZoneDataArray || this.omrZoneDataArray.length <= 0) {
        return true
      }

      let isValid = true
      this.omrZoneDataArray.map(omrZone => {
        if (!omrZone.name || omrZone.name.trim() === '') {
          isValid = false
        }
        if (!omrZone.omrTypeId || omrZone.omrTypeId === 0) {
          isValid = false
        }
        if (!omrZone.indexFieldName) {
          isValid = false
        }
        return true
      })
      return isValid
    },
    async clearDeleteOnInput() { await this.$store.dispatch('digitalSignature/setDeleteOnInput', false) },
    displayCurrentZonePanel() {
      let found = false;
      this.zoneDetailsPanelType = this.activeAIPZone.subType
      switch (this.zoneDetailsPanelType) {
        case this.annotationConfig.checkZone.subType:
          // eslint-disable-next-line no-case-declarations
          found = this.checkZoneDataArray.find(element => element.annGUID === this.activeAIPZone.uniqueId)
          if (!found) {
            found = this.getEmptyCheckZone()
            found.annGUID = this.activeAIPZone.uniqueId
          }

          if (!found.compareValue) {
            found.compareValue = ''
          }

          this.currentCheckZoneData = found
          break;
        case this.annotationConfig.omrZone.subType:
          found = this.omrZoneDataArray.find(e => e.annGuid === this.activeAIPZone.uniqueId)
          if (!found) {
            found = this.getEmptyOmrZone()
            found.annGuid = this.activeAIPZone.uniqueId
          }
          this.currentOmrZoneData = found
          this.omrTypeObj = this.omrTypeList.find(e => e.value === found.omrTypeId)
          this.currentOmrZoneData.indexFieldObject = this.indexFieldsForForm.find(e => e.value === found.indexFieldName)
          if (!this.omrTypeObj) {
            this.omrTypeObj = { name: '', value: 0 }
          }
          if (!this.currentOmrZoneData.indexFieldObject) {
            this.currentOmrZoneData.indexFieldObject = { name: '', value: '' }
          }
          break;
        case this.annotationConfig.textZone.subType:
        case this.annotationConfig.barcodeZone.subType:
          found = this.textAndBarcodeZoneDataArray.find(element => element.annGuid === this.activeAIPZone.uniqueId)

          if (!found) {
            found = this.getEmptyTextZone(this.zoneDetailsPanelType)
            found.annGuid = this.activeAIPZone.uniqueId
          }
          this.currentTextOrBarcodeData = found
          this.characterFilter = this.characterFilterList.find(e => e.value === found.charSet)
          this.currentTextOrBarcodeData.field = this.indexFieldsForForm.find(e => e.value === found.indexFieldName)
          if (!this.currentTextOrBarcodeData.field) {
            this.currentTextOrBarcodeData.field = { name: '', value: '' }
          }
          break;
        default:
          this.currentCheckZoneData = this.getEmptyCheckZone()
          this.currentTextOrBarcodeData = this.getEmptyTextZone()
          this.currentOmrZoneData = this.getEmptyOmrZone()
          break
      }
    },
    getEmptyCheckZone() {
      const result = {
        annGUID: '',
        checkID: 0,
        checkName: '',
        compareValue: '',
        compareValueObject: {
          name: '',
          value: '',
        },
        isBarcode: false,
        moveToPage: null,
        moveToPageChecked: false,
        originPageNumber: this.currentPageNumber,
        pageNumbers: [],
        pageNumbersString: '',
        rotateZone: false,
        textCompare: '',
      }

      return result
    },
    getEmptyOmrZone() {
      const result = {
        aipId: 0,
        annGuid: '',
        appendValue: false,
        fullText: 'True',
        emptyText: 'False',
        indexFieldObject: { name: '', value: '' },
        indexFieldName: '',
        name: '',
        omrTypeId: 0,
        percentFull: 0,
        originPageNumber: this.currentPageNumber,
        originalImageHeightInches: 100.00,
        originalImageWidthInches: 100.00,
        rotateZone: false,
      }
      return result
    },
    getEmptyTextZone(type) {
      const zone = {
        aipId: 0,
        annGuid: '',
        burstOnTextFound: false,
        burstOnBarcode: false,
        burstTextValues: '',
        charSet: 0,
        concatSymbol: '',
        concatData: false,
        appendData: false,
        name: '',
        extractDelChar: ' ',
        extractText: false,
        extractWordCount: 0,
        extractFromLeft: false,
        extractPosition: 0,
        indexFieldName: '',
        isBarcode: type === 'Barcode Zone',
        removeSpaces: false,
        meanConfidence: 0.00,
        originalImageHeightInches: 100.00,
        originalImageWidthInches: 100.00,
        originPageNumber: this.currentPageNumber,
        zoneProcessingID: 0,
        field: {
          name: '',
          value: '',
        },
      }
      return zone
    },
    goToFirstPage() { this.goToPage(1) },
    goToLastPage() { this.goToPage(this.totalPages) },
    goToNextPage() {
      if ((this.currentPageNumber + 1) <= this.totalPages) {
        this.goToPage(this.currentPageNumber + 1)
      }
    },
    async goToPage(pageNumber) {
      if (typeof pageNumber !== 'undefined' && pageNumber > 0 && pageNumber <= this.totalPages) {
        if (this.currentPage !== pageNumber) {
          await this.$store.dispatch('document/resetZoomValue')
        }

        this.currentPageNumber = pageNumber
      }
    },
    goToPreviousPage() {
      if (this.currentPageNumber > 1) {
        this.goToPage(this.currentPageNumber - 1)
      }
    },
    save() {
      if (!this.cleanAndValidateData() || !this.validateTextZonesData() || !this.cleanAndValidateOmrData()) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'One or More Zones are Invalid'
        this.snackbarSubTitle = 'Please confirm all required fields have been entered in each Zone'
        this.snackbarImage = false
        return
      }

      this.$store.dispatch('capture/setAipCheckZoneArray', this.checkZoneDataArray)
      this.$store.dispatch('capture/setAipOmrZoneArray', this.omrZoneDataArray)
      this.$store.dispatch('capture/setAipTextZoneArray', this.textAndBarcodeZoneDataArray)

      this.$router.push({ name: 'EditAIP', params: { AIPID: -1 } })
    },
    async setDeleteOnInput() { await this.$store.dispatch('digitalSignature/setDeleteOnInput', true) },
    updateCurrentZoneData() {
      switch (this.zoneDetailsPanelType) {
        case this.annotationConfig.checkZone.subType:
          if (this.currentCheckZoneData?.annGUID) {
            // remove the old data and replace with the updated
            this.checkZoneDataArray = this.checkZoneDataArray.filter((element) => {
              return element.annGUID !== this.currentCheckZoneData.annGUID
            })

            if (!this.activeAIPZone.previousWasDeleted) {
              this.checkZoneDataArray.push(this.currentCheckZoneData)
            }
          }
          break;
        case this.annotationConfig.omrZone.subType:
          if (this.currentOmrZoneData?.annGuid) {
            this.omrZoneDataArray = this.omrZoneDataArray.filter(e => {
              return e.annGuid !== this.currentOmrZoneData.annGuid
            })

            if (!this.activeAIPZone.previousWasDeleted) {
              this.omrZoneDataArray.push(this.currentOmrZoneData)
            }
          }
          break;
        case this.annotationConfig.textZone.subType:
        case this.annotationConfig.barcodeZone.subType:
          if (this.currentTextOrBarcodeData?.annGuid) {
            this.textAndBarcodeZoneDataArray = this.textAndBarcodeZoneDataArray.filter((element) => {
                return element.annGuid !== this.currentTextOrBarcodeData.annGuid
            })
            if (!this.activeAIPZone.previousWasDeleted) {
              this.textAndBarcodeZoneDataArray.push(this.currentTextOrBarcodeData)
            }
          }
          break;
        default:
          break;
      }
    },
    toggleCheckZoneLookForBarcode() {
      if (this.currentCheckZoneData) {
        this.currentCheckZoneData.isBarcode = !this.currentCheckZoneData.isBarcode
      }
    },
    toggleCheckZoneMoveToPage() {
      if (this.currentCheckZoneData) {
        this.currentCheckZoneData.moveToPageChecked = !this.currentCheckZoneData.moveToPageChecked
      }
    },
    toggleOmrZoneAppendData() {
      if (this.currentOmrZoneData) {
        this.currentOmrZoneData.appendValue = !this.currentOmrZoneData.appendValue
      }
    },
    toggleTextExtractText() {
      if (this.currentTextOrBarcodeData) {
        this.currentTextOrBarcodeData.extractText = !this.currentTextOrBarcodeData.extractText
      }
    },
    toggleTextZoneAppendText() {
      if (this.currentTextOrBarcodeData) {
        this.currentTextOrBarcodeData.appendData = !this.currentTextOrBarcodeData.appendData
      }
    },
    toggleTextZoneBurstOnTextFound() {
      if (this.currentTextOrBarcodeData) {
        this.currentTextOrBarcodeData.burstOnTextFound = !this.currentTextOrBarcodeData.burstOnTextFound
      }
    },
    toggleTextZoneRemoveSpaces() {
      if (this.currentTextOrBarcodeData) {
        this.currentTextOrBarcodeData.removeSpaces = !this.currentTextOrBarcodeData.removeSpaces
      }
    },
    validateTextZonesData() {
      this.updateCurrentZoneData()

      if (!this.textAndBarcodeZoneDataArray) {
        this.textAndBarcodeZoneDataArray = []
      }
      this.textAndBarcodeZoneDataArray.filter((element) => {
        return element.annGUID !== ''
      })

      if (!this.textAndBarcodeZoneDataArray || this.textAndBarcodeZoneDataArray.length <= 0) {
        return true
      }

      let isValid = true
      this.textAndBarcodeZoneDataArray.map((textZone) => {
        if (!textZone.name || textZone.name.trim() === '') {
          isValid = false
        }
        if (!textZone.indexFieldName) {
          isValid = false
        }

        if (textZone.extractText && (!textZone.extractDelChar && !textZone.extractPosition)) {
          isValid = false
        }

        if (textZone.charSet === null) {
          isValid = false
        }

        return true
      })
      return isValid
    },
    zoomIn() {
      if (this.zoomValue > 3) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', true)
      }
    },
    zoomOut() {
      if (this.zoomValue === 1) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'WARNING'
        this.snackbarSubTitle = 'Unable to zoom past this point.'
        this.snackbarImage = false
      } else {
        this.$store.dispatch('document/setZoomValue', false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  .panel-component-bottom-padding {
    padding-bottom: 16px;
  }

  .panel-component-bottom-padding-small {
    padding-bottom: 8px;
  }

  .innerDiv {
    padding-top: 10px;
  }
  .close-button{
    margin: 0px 15px;
    align-items: center;
    background-color: $grey-75;
    border-radius: 8px;
  }

  .document-title {
    font-family: $font-family-bold;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: black;
    text-align: left;
    padding-left: 8px;
  }
  .document-subtitle {
    font-family: Archivo;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $grey-400;
    display: flex;
    align-items: center;
  }
  .subtitle-icon {
    margin-right: 8px;
  }
  .colorBlack {
    fill: black;
  }
  .marginBottom {
    margin-bottom: 10px;
  }
  .divider {
    width: 1px;
    height: 24px;
    margin: 0px 10px;
    background-color: $divider-light-grey;
  }

  .toolbar-div {
    border-bottom: solid 1px $divider-light-grey;
    padding-bottom: 5px;
  }

  .control-box {
    margin: 0px 15px;
    display: flex;
    align-items: center;
  }
  .filepond--root{
    background-color: red;
    color: red;
  }
  .new-document .filepond--root {
    height: 300px !important;
    background-color: red !important;
  }
  .middle-section {
    background-color: $grey-25;
    height: calc(100vh - 235px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;

    .document-loaded {
      width:  90%;
      height: 100%;
      margin: 15px;
    }
    .upload-container {
      width: 672px;
      height: 376px;
      padding: 126px 236px;
      border-radius: 8px;
      border: dashed 1px $grey-300;
    }

  }

  .right-sidebar {
    width: 336px;
    height: calc(100vh - 235px);
    padding: 16px 16px 0;
    box-shadow: -1px 0 0 0 $grey-75;
    overflow: auto;
    .right-sidebar-title {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 8px;
    }
    .no-zone-selected {
      font-family: Archivo;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .no-zone-image {
        margin: 20px 0px;
      }
      .no-zone-selected-title {
        font-size: 16px;
        font-weight: 500;
      }
      .no-zone-selected-message {
        text-align: center;
        line-height: 1;
        font-size: 12px;
        color: $grey-400;
      }

    }
  }
  @media screen and (max-width: 1600px) {
    .middle-section {
      width: 600px;
    }
    .right-sidebar {
      min-width: 336px;
      width: 336px;
    }
  }

  @media screen and (max-width: 1108px) {
    .right-sidebar {
    }
    .document-upload {
      width: 50%;
    }
    .upload-container {
      width: 60%;
    }
  }
</style>
